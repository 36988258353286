export default defineNuxtRouteMiddleware(({ fullPath, name, query }) => {
  if (import.meta.server) return // fallback if route is not ssr: false in nuxt.config routeRules
  const { isLoggedIn } = useAuth()
  if (name === 'login' && isLoggedIn.value) {
    const target = (query.redirect as string) || '/account'
    return navigateTo(target)
  }

  if (name !== 'login' && !isLoggedIn.value) {
    return navigateTo({
      name: 'login',
      query: {
        redirect: encodeURIComponent(fullPath),
      },
    })
  }
})
